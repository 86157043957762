/* Body & Image List Styles
-------------------------------------------------------------- */
body {
  background: #000;
  padding: 2.5%;
}

.img-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.img-list li {
  border: 5px solid transparent;
  box-sizing: border-box;
  width: 33.33%;
  float: left;
  position: relative;
  cursor: pointer;
}

.img-list li.double {
  border: 5px solid transparent;
  box-sizing: border-box;
  width: 66.66%;
  float: left;
  position: relative;
  cursor: pointer;
}

.img-list img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  vertical-align: middle;
}

/* Image Placeholder Styles
  -------------------------------------------------------------- */
.img-placeholder:before,
.img-placeholder:after {
  padding-top: 33.33%;
  content: "";
  display: block;
}

.img-placeholder {
  background: #222;
  box-shadow: 0 0 0 8px #222 inset, 0 0 0 9px #333 inset;
  color: #444;
  line-height: 0;
  text-align: center;
  display: block;
}

/* The hover effect
  -------------------------------------------------------------- */
.img-list li:before {
  transition: all .5s ease;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #333;
  transform: scale(0);
}

.img-list li:hover:before {
  opacity: .5;
  transform: scale(1);
}

.img-list li:after {
  transition: all .6s ease .2s;
  content: "";
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: 8px;
  border: 1px solid #aaa;
  background: #000;
  opacity: 0;
  transform: scale(0);
}

.img-list li:hover:after {
  opacity: .35;
  transform: scale(1);
}