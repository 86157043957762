@import url(https://fonts.googleapis.com/earlyaccess/droidarabickufi.css);
@import url(https://fonts.cdnfonts.com/css/raleway-5);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.droid-arabic-kufi {
  font-family: 'Droid Arabic Kufi', 'Trebuchet MS', serif;
}

body,
h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  font-weight: 'Raleway', "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji",
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}
/* https://codepen.io/DuskoStamenic/pen/ZEvBKdw */

:root {
  --color: rgb(63 63 63);
}

body {
  margin: 0;
  font-family: Poppins, sans-serif;
}

.site-heading {
  color: white;
}

.parallax-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /*  this is where the magic happens:  */
  background-attachment: fixed;

  background-image: url('/assets//img/profile_mirror.jpg');
  background-position: center;
  background-size: cover;
  color: rgb(63 63 63);
  color: var(--color);
  grid-row-gap: 4rem;
  row-gap: 4rem;

  text-align: center;
}

.buffer {
  width: 100%;
  min-height: 60vh;
  -webkit-filter: grayscale(80%);
          filter: grayscale(80%);
  transform: scaleX(-1);

  background-image: linear-gradient(rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.70)), url('/assets/img/bridge.jpg');
  background-position: center;

  background-size: cover;

}

.buffer p {
  transform: scaleX(-1);
}

.parallax-container2 {
  flex-direction: row;
  flex-wrap: wrap;
  -webkit-filter: grayscale(60%);
          filter: grayscale(60%);

  padding: 6rem 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 0.20)), url('/assets/img/background_words.jpg');
  grid-gap: 1.6em;
  gap: 1.6em;
}

body.dark .parallax-container2 {
  -webkit-filter: grayscale(90%) !important;
          filter: grayscale(90%) !important;
  background-image: linear-gradient(rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.90)), url('/assets/img/background_words.jpg');

}

h1 {
  font-size: 3rem;
  font-weight: 700;
}

p {
  max-width: 52ch;
  padding: 1em;
  line-height: 1.5;
}


h3 {
  margin-top: 1em;
  font-size: 1.6rem;
  font-weight: 700;
}

.card {
  display: flex;
  max-width: 320px;
  flex-direction: column;
  align-items: center;

  padding-bottom: 1rem;
  border-radius: 0.5em;

  background-color: white;
  box-shadow: 0 29px 38px -15px rgb(0 0 0 / 43%);
}

.card-img {
  width: 100%;
  height: 200px;
  border-radius: 0.3em;
  background-position: center;
  background-size: cover;
}

.card:nth-of-type(1) .card-img {
  background-image: url(https://images.unsplash.com/photo-1551632811-561732d1e306?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80);
}

.card:nth-of-type(2) .card-img {
  background-image: url(https://images.unsplash.com/photo-1522163182402-834f871fd851?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1606&q=80);
}

.card:nth-of-type(3) .card-img {
  background-image: url(https://images.unsplash.com/photo-1628746404106-4d3843b231b3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8Y2F2aW5nfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=900&q=60);
}

.card:nth-of-type(4) .card-img {
  background-image: url(https://images.unsplash.com/photo-1496429862132-5ab36b6ae330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=900&q=80);
}
.post-subtitle {
  text-align: start !important;
  unicode-bidi: plaintext;
  margin-top: 0;
  font-weight: bold;
}

p {
  white-space: pre;
}

h2 {
  text-align: start !important;
  color: white;
}

h2:hover {
  color: white;
  text-decoration: underline;
}


.post-meta {
  margin: 0;
  font-size: 10px;
  text-align: right;
}

.no-title {
  font-size: 12px;
  font-style: italic;
}
.search-row .form-control {
  background-color: rgba(0, 0, 0, 0.4);
  flex-grow: 1;
  color: white;
  flex: 3 1;
  border: 0px;
}

.search-row {
  border: 1px solid white;
  border-radius: 50px;
}

.search-row .btn {
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  flex: 1 1
}

.search-row .btn:hover {
  text-decoration: underline;
}

.btn:hover {
  text-decoration: underline;
  font-weight: normal;
}

.btn {

  font-weight: normal;
}

.btn.active {
  text-decoration: underline;
  font-weight: bold;
}
/* Body & Image List Styles
-------------------------------------------------------------- */
body {
  background: #000;
  padding: 2.5%;
}

.img-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.img-list li {
  border: 5px solid transparent;
  box-sizing: border-box;
  width: 33.33%;
  float: left;
  position: relative;
  cursor: pointer;
}

.img-list li.double {
  border: 5px solid transparent;
  box-sizing: border-box;
  width: 66.66%;
  float: left;
  position: relative;
  cursor: pointer;
}

.img-list img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  vertical-align: middle;
}

/* Image Placeholder Styles
  -------------------------------------------------------------- */
.img-placeholder:before,
.img-placeholder:after {
  padding-top: 33.33%;
  content: "";
  display: block;
}

.img-placeholder {
  background: #222;
  box-shadow: 0 0 0 8px #222 inset, 0 0 0 9px #333 inset;
  color: #444;
  line-height: 0;
  text-align: center;
  display: block;
}

/* The hover effect
  -------------------------------------------------------------- */
.img-list li:before {
  transition: all .5s ease;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #333;
  transform: scale(0);
}

.img-list li:hover:before {
  opacity: .5;
  transform: scale(1);
}

.img-list li:after {
  transition: all .6s ease .2s;
  content: "";
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: 8px;
  border: 1px solid #aaa;
  background: #000;
  opacity: 0;
  transform: scale(0);
}

.img-list li:hover:after {
  opacity: .35;
  transform: scale(1);
}
/* https://codepen.io/DuskoStamenic/pen/ZEvBKdw */
