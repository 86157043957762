/* https://codepen.io/DuskoStamenic/pen/ZEvBKdw */
@import "https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap";

:root {
  --color: rgb(63 63 63);
}

body {
  margin: 0;
  font-family: Poppins, sans-serif;
}

.site-heading {
  color: white;
}

.parallax-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /*  this is where the magic happens:  */
  background-attachment: fixed;

  background-image: url('/assets//img/profile_mirror.jpg');
  background-position: center;
  background-size: cover;
  color: var(--color);
  row-gap: 4rem;

  text-align: center;
}

.buffer {
  width: 100%;
  min-height: 60vh;
  filter: grayscale(80%);
  transform: scaleX(-1);

  background-image: linear-gradient(rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.70)), url('/assets/img/bridge.jpg');
  background-position: center;

  background-size: cover;

}

.buffer p {
  transform: scaleX(-1);
}

.parallax-container2 {
  flex-direction: row;
  flex-wrap: wrap;
  filter: grayscale(60%);

  padding: 6rem 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 0.20)), url('/assets/img/background_words.jpg');
  gap: 1.6em;
}

body.dark .parallax-container2 {
  filter: grayscale(90%) !important;
  background-image: linear-gradient(rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.90)), url('/assets/img/background_words.jpg');

}

h1 {
  font-size: 3rem;
  font-weight: 700;
}

p {
  max-width: 52ch;
  padding: 1em;
  line-height: 1.5;
}


h3 {
  margin-top: 1em;
  font-size: 1.6rem;
  font-weight: 700;
}

.card {
  display: flex;
  max-width: 320px;
  flex-direction: column;
  align-items: center;

  padding-bottom: 1rem;
  border-radius: 0.5em;

  background-color: white;
  box-shadow: 0 29px 38px -15px rgb(0 0 0 / 43%);
}

.card-img {
  width: 100%;
  height: 200px;
  border-radius: 0.3em;
  background-position: center;
  background-size: cover;
}

.card:nth-of-type(1) .card-img {
  background-image: url(https://images.unsplash.com/photo-1551632811-561732d1e306?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80);
}

.card:nth-of-type(2) .card-img {
  background-image: url(https://images.unsplash.com/photo-1522163182402-834f871fd851?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1606&q=80);
}

.card:nth-of-type(3) .card-img {
  background-image: url(https://images.unsplash.com/photo-1628746404106-4d3843b231b3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8Y2F2aW5nfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=900&q=60);
}

.card:nth-of-type(4) .card-img {
  background-image: url(https://images.unsplash.com/photo-1496429862132-5ab36b6ae330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=900&q=80);
}