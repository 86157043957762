.search-row .form-control {
  background-color: rgba(0, 0, 0, 0.4);
  flex-grow: 1;
  color: white;
  flex: 3;
  border: 0px;
}

.search-row {
  border: 1px solid white;
  border-radius: 50px;
}

.search-row .btn {
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  flex: 1
}

.search-row .btn:hover {
  text-decoration: underline;
}

.btn:hover {
  text-decoration: underline;
  font-weight: normal;
}

.btn {

  font-weight: normal;
}

.btn.active {
  text-decoration: underline;
  font-weight: bold;
}