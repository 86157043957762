.post-subtitle {
  text-align: start !important;
  unicode-bidi: plaintext;
  margin-top: 0;
  font-weight: bold;
}

p {
  white-space: pre;
}

h2 {
  text-align: start !important;
  color: white;
}

h2:hover {
  color: white;
  text-decoration: underline;
}


.post-meta {
  margin: 0;
  font-size: 10px;
  text-align: right;
}

.no-title {
  font-size: 12px;
  font-style: italic;
}